.banner_image {
  position: absolute;
  display: block;
  top: "0px";
  width: 100%;
  height: 100%;
}
.banner_image_mobile {
  position: absolute;
  top: "0px";
  width: 100%;
}

#image_1 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/lp_backgrounds%2Fimage_1.webp?alt=media&token=15aa9925-cf5f-4301-8782-95fa39e0f02e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#image_2 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/lp_backgrounds%2Fimage_2.webp?alt=media&token=d3d0e11f-a505-468e-aee0-d39d0f1a5933");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#image_3 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/lp_backgrounds%2Fimage_3.webp?alt=media&token=4d314edc-6b55-4659-a6f4-d3a695a27927");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#image_4 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/lp_backgrounds%2Fimage_4.webp?alt=media&token=a9b71211-43e9-4a69-9c4c-02eaf50b33f6");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
