body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Mobile */

@media only screen and (max-width: 767px) {
  .company-slogan {
    font-size: 1rem;
    color: #ffffff;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
  }
  .company-name {
    font-size: 1.2rem;
    color: #ffffff;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
  }

  .big-heading {
    font-weight: 900;
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
  }

  .large-text {
    font-weight: 200;
    font-size: 0.7rem;
    font-family: "Montserrat", sans-serif;
  }

  .small-text {
    font-weight: 200;
    font-size: 0.5rem;
    font-family: "Montserrat", sans-serif;
  }
}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .company-slogan {
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
  }

  .company-name {
    font-size: 3rem;
    color: #ffffff;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
  }

  .big-heading {
    font-weight: 900;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
  }

  .large-text {
    font-weight: 200;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
  }

  .small-text {
    font-weight: 200;
    font-size: 0.7rem;
    font-family: "Montserrat", sans-serif;
  }
}

/* Computer/Desktop Monitor */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .company-slogan {
    font-size: 3rem;
    color: #ffffff;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
  }

  .company-name {
    font-size: 4rem;
    color: #ffffff;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
  }

  .big-heading {
    font-weight: 900;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
  }

  .large-text {
    font-weight: 200;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
  }

  .small-text {
    font-weight: 200;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
  }
}

/* Large Monitor */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .company-slogan {
    font-size: 3rem;
    color: #ffffff;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
  }

  .company-name {
    font-size: 4rem;
    color: #ffffff;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
  }

  .big-heading {
    font-weight: 900;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
  }

  .large-text {
    font-weight: 200;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
  }

  .small-text {
    font-weight: 200;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
  }
}

/* WideScreen Monitor */
@media only screen and (min-width: 1920px) {
  /* .company-slogan {
    font-size: 1.4rem;
    color: #ffffff;
  }

  .large-text {
    font-size: 1.6rem;
    font-family: "Montserrat", sans-serif;
  } */
}

.backscroll-image {
  position: fixed;
  z-index: -5;
  object-fit: cover;
}

.dark-tint {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  opacity: 0.7;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}

.dark-tint-text {
  color: white;
  display: "table-cell";
  vertical-align: middle;
}

.hover-image {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  background-color: #d35f29;
  opacity: 0.9;
  filter: alpha(opacity=90);
  -webkit-filter: alpha(opacity=90);
  text-align: center;
}

.hover-image:hover {
  width: 0%;
  height: 0%;

  background-color: #f5bd20;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-filter: alpha(opacity=100);

  transform: scale(0.7, 0.7);
  -webkit-transform: scale(0.7, 0.7);
  -moz-transform: scale(0.7, 0.7);
  -o-transform: scale(0.7, 0.7);

  transition: all 400ms;
  -o-transition: all 400ms;
  -moz-transition: all 400ms;
  -webkit-transition: all 400ms;
}

.page-header-parent {
  position: relative;
  text-align: center;
  width: "100%";
  height: "100%";
}

.page-header-content {
  color: white;
  position: absolute;
  display: table-cell;
  top: 45%;
  left: 5%;
  font-weight: 600;
  vertical-align: middle;
}

.vi-tab:hover {
  cursor: pointer;
  background-color: #ffd700;
  color: white;
}

.vi-tab-selected {
  background-color: orange;
  color: white;
  font-weight: bold;
}

.gallary-background {
  background: url("/public/assets/industry_image_1.jpg") !important;
  background-position: center;
}

.industry_image {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/lp_backgrounds%2Findustry_image_1.webp?alt=media&token=601b6544-fbb0-4fad-bf67-6ef16f234272") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner_image {
  position: absolute;
  display: block;
  top: "0px";
  width: 100%;
  height: 100%;
}
.banner_image_mobile {
  position: absolute;
  top: "0px";
  width: 100%;
}

#image_1 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/lp_backgrounds%2Fimage_1.webp?alt=media&token=15aa9925-cf5f-4301-8782-95fa39e0f02e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#image_2 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/lp_backgrounds%2Fimage_2.webp?alt=media&token=d3d0e11f-a505-468e-aee0-d39d0f1a5933");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#image_3 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/lp_backgrounds%2Fimage_3.webp?alt=media&token=4d314edc-6b55-4659-a6f4-d3a695a27927");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#image_4 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/lp_backgrounds%2Fimage_4.webp?alt=media&token=a9b71211-43e9-4a69-9c4c-02eaf50b33f6");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner_image {
  position: absolute;
  top: "0px";
  width: 100%;
}


